<template>
  <el-drawer append-to-body id="projFormDrawer" :with-header="false" :visible="drawerVisable" :size="drawerSize">
    <div class="drawerHeader" ref="drawerHeader">
      <p class="title">{{ $t('ResourceManagement.customer.followProgress') }}</p>
      <div class="box">
        <div class="circle"></div>
        <p class="currentRord">{{ this.customerInfo }}</p>
      </div>
      <div class="header-btn-group">
        <p @click="afterCloseClear()" class="closeDrawder">
          {{ $t('ResourceManagement.customer.backToList') }}
        </p>
      </div>
    </div>
    <div class="app-box">
      <div ref="formContainer" class="formContainer">
        <el-form ref="drawerForm" class="form-box" :model="drawerForm">
          <el-form-item prop="bdIdEq" label="BD">
            <el-select v-model="drawerForm.bdIdEq" filterable placeholder="请输入或者下拉选择">
              <el-option v-for="item in optionGroup.bdOption" :key="item.id" :label="item.name" :value="item.id"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="contactNameCont" :label="$t('ResourceManagement.customer.contactName')">
            <el-input v-model="drawerForm.contactNameCont" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item prop="policymakerCont" :label="$t('ResourceManagement.customer.policymaker')">
            <el-input v-model="drawerForm.policymakerCont" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item prop="keyword" :label="$t('ResourceManagement.customer.keywords')">
            <el-input v-model="drawerForm.keyword" :placeholder="$t('ResourceManagement.customer.KeywordTips')"></el-input>
          </el-form-item>
          <el-form-item prop="dateVal" :label="$t('ResourceManagement.customer.trackingAt')" class="formLabel">
            <el-date-picker
              v-model="drawerForm.dateVal"
              class="el-date-picker"
              type="daterange"
              range-separator="至"
              :unlink-panels="true"
              :start-placeholder="startPlaceholder"
              :end-placeholder="endPlaceholder"
            ></el-date-picker>
          </el-form-item>
          <el-form-item class="buttonGroup">
            <el-button @click="resetForm" class="btn" type="default" size="mini">{{ $t('commonVariables.reset') }}</el-button>
            <el-button @click="search" class="btn" type="primary" size="mini">{{ $t('commonVariables.filtrate') }}</el-button>
          </el-form-item>
        </el-form>
      </div>

      <div class="bottom-box">
        <div class="header" ref="tableHeader">
          <p style="margin: 0">{{ $t('ResourceManagement.customer.followRecord') }}</p>
          <div class="header-btns">
            <el-button @click="addRecord" class="btn" type="default" size="mini">
              {{ $t('ResourceManagement.customer.addRecord') }}
            </el-button>
            <el-button @click="uploadVisable = true" class="btn" type="primary" size="mini">
              {{ $t('ResourceManagement.customer.uploadRecord') }}
            </el-button>
          </div>
        </div>
        <EditServiceItemTable
          ref="table"
          :applyComp="'customer'"
          :tableLoading="tableLoading"
          :tableHeight="proDrawerHeight"
          :isIndex="false"
          :editDiago="true"
          :deleteTips="$t('ResourceManagement.customer.deleteFollowTips')"
          :authGroup="drawerAuthGroup"
          @deleteData="deleteItem"
          @editServiceItem="editItem"
          :serviceTableData="oriTableData"
          :chooseInputArr="chooseInputArr"
          :count="count"
          @pageChange="pageChange"
          :page.sync="drawerForm.page"
          :pagesize.sync="drawerForm.pagesize"
        />
      </div>
    </div>
    <el-drawer
      class="record"
      :wrapperClosable="false"
      :show-close="false"
      :modal="false"
      :title="recordForm.id ? $t('ResourceManagement.customer.editRecord') : $t('ResourceManagement.customer.addRecord')"
      :visible.sync="recordVisable"
      :size="$i18n.locale == 'en' ? 960 : 860"
    >
      <el-form
        ref="recordForm"
        label-position="right"
        :label-width="$i18n.locale == 'en' ? '180px' : '120px'"
        :model="recordForm"
        :rules="recordRules"
      >
        <div class="recordForm">
          <div class="leftForm">
            <el-form-item prop="trackingAt" :label="$t('ResourceManagement.customer.trackingAt')">
              <el-date-picker
                :value-format="'yyyy-MM-dd'"
                size="mini"
                type="date"
                v-model="recordForm.trackingAt"
                class="el-date-picker"
              ></el-date-picker>
            </el-form-item>
            <el-form-item prop="contactName" :label="$t('ResourceManagement.customer.contactName')">
              <el-autocomplete
                class="inline-input"
                v-model="recordForm.contactName"
                value-key="name"
                value="name"
                :fetch-suggestions="querySearch"
                placeholder="请输入内容"
                @select="handleSelect"
                @change="handleChange"
              ></el-autocomplete>
            </el-form-item>
            <el-form-item prop="contactPhone" :label="$t('ResourceManagement.customer.contactPhone')">
              <el-input v-model="recordForm.contactPhone" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item prop="projectCountPerYear" :label="$t('ResourceManagement.customer.projectCountPerYear')">
              <el-input v-model.number="recordForm.projectCountPerYear" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item prop="projectBudget" :label="$t('ResourceManagement.customer.projectBudget')">
              <el-input v-model.number="recordForm.projectBudget" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item prop="sponsorId" :label="$t('ResourceManagement.customer.sponsorName')">
              <my-select :options="optionGroup.sponsorOption" v-model="recordForm.sponsorId" placeholder="请输入或者下拉选择"></my-select>
            </el-form-item>
          </div>
          <div class="rightForm">
            <el-form-item prop="bdId" :label="$t('ResourceManagement.customer.bdName')">
              <my-select :options="optionGroup.bdOption" v-model="recordForm.bdId" placeholder="请输入或者下拉选择"></my-select>
            </el-form-item>
            <el-form-item prop="contactPosition" :label="$t('ResourceManagement.customer.contactPosition')">
              <el-input v-model="recordForm.contactPosition" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item prop="policymaker" :label="$t('ResourceManagement.customer.policymaker')">
              <el-input v-model="recordForm.policymaker" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item prop="projectByStages" :label="$t('ResourceManagement.customer.projectByStages')">
              <el-input v-model.number="recordForm.projectByStages" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item prop="projectBeginAt" :label="$t('ResourceManagement.customer.projectBeginAt')">
              <el-date-picker
                size="mini"
                type="date"
                v-model="recordForm.projectBeginAt"
                :value-format="'yyyy-MM-dd'"
                class="el-date-picker"
              ></el-date-picker>
            </el-form-item>
          </div>
        </div>
        <div class="textareaZone">
          <el-form-item prop="customerDemand" :label="$t('ResourceManagement.customer.customerDemand')">
            <el-input :rows="6" show-word-limit v-model="recordForm.customerDemand" type="textarea"></el-input>
          </el-form-item>
          <el-form-item prop="policyFlow" :label="$t('ResourceManagement.customer.policyFlow')">
            <el-input :rows="5" show-word-limit v-model="recordForm.policyFlow" type="textarea"></el-input>
          </el-form-item>
          <el-form-item prop="actionPlan" :label="$t('ResourceManagement.customer.actionPlan')">
            <el-input :rows="5" show-word-limit v-model="recordForm.actionPlan" type="textarea"></el-input>
          </el-form-item>
        </div>
      </el-form>
      <div class="button-group">
        <el-button class="btn" size="mini" type="default" @click="recordClose($t('ResourceManagement.detail.closeDetailTips'))">{{
          $t('commonVariables.cancel')
        }}</el-button>
        <el-button class="btn" size="mini" type="primary" @click="recordSave">{{ $t('commonVariables.save') }}</el-button>
      </div>
    </el-drawer>
    <el-dialog :modal="false" :visible.sync="uploadVisable" :width="$i18n.locale == 'en' ? '360px' : '289px'">
      <el-form
        ref="uploadForm"
        label-position="right"
        :label-width="$i18n.locale == 'en' ? '180px' : '100px'"
        :model="uploadForm"
        :rules="uploadRules"
      >
        <el-form-item prop="fileList" :label="$t('ResourceManagement.customer.chooseFile')" v-if="drawerAuthGroup.create">
          <el-upload
            class="upload-excel"
            action="string"
            ref="upload"
            :http-request="httpRequest"
            :before-remove="handleRemove"
            :file-list="uploadForm.fileList"
          >
            <el-button class="btn" size="mini" type="primary">{{ $t('ResourceManagement.customer.searchFile') }}</el-button>
          </el-upload>
        </el-form-item>
        <el-form-item :label="$t('ResourceManagement.customer.dowloadTemplate')">
          <el-link class="downUrl" :href="downUrl" target="_blank">{{ $t('ResourceManagement.customer.download') }}</el-link>
          <!-- <el-button class="btn" size="mini" type="default" @click="downloadFile">点击下载</el-button> -->
        </el-form-item>
      </el-form>
      <div class="foot-button-group">
        <el-button @click="leadingInCancel">{{ $t('commonVariables.cancel') }}</el-button>
        <el-button type="primary" @click="uploadRecord">{{ $t('ResourceManagement.customer.import') }}</el-button>
      </div>
    </el-dialog>
  </el-drawer>
</template>

<script>
import resourceManagementApi from '@/api/modules/resourceManagement.api';
import MySelect from '@/components/common/MySelect';
import { dayFormat } from '@/utils/time-tools';
import EditServiceItemTable from './EditServiceItemTable';

export default {
  components: {
    EditServiceItemTable,
    MySelect,
  },
  name: 'ProjFormDrawer',
  props: {
    allBds: {
      type: Array,
      default() {
        return [];
      },
    },
    customer_row: {
      type: Object,
      default() {
        return null;
      },
    },
    drawerVisable: {
      type: Boolean,
      default: false,
    },
    tableType: {
      type: String,
      default: 'create',
    },
  },
  watch: {
    drawerVisable: {
      immediate: false,
      deep: true,
      handler(newVal) {
        if (newVal && this.firstCreate) {
          this.$nextTick(() => {
            this.resizeObserverSize();
            this.getDrawerOptions();
          });
          window.addEventListener('resize', this.resizeObserverSize);
          this.firstCreate = false;
        }
        if (newVal) {
          this.customerInfo = this.customer_row ? this.customer_row.code + '   ' + this.customer_row.name : '';
          this.getTableList();
        }
      },
    },
  },
  computed: {
    downUrl() {
      return '/api/pmf/v1/resource/customerTrackings/importTemplate';
    },
  },
  activated() {
    this.$nextTick(() => {
      !this.firstCreate ? this.resizeObserverSize() : null;
    });
    window.addEventListener('resize', this.resizeObserverSize);
  },
  deactivated() {
    window.removeEventListener('resize', this.resizeObserverSize);
  },

  data() {
    return {
      proDrawerHeight: 0,
      firstCreate: true,
      drawerSize: '90%',
      drawerAuthGroup: {
        create: false,
        edit: false,
        del: false,
      },
      tableLoading: false,
      tableList: [],
      oriTableData: [],
      customerInfo: '',
      count: 0,
      otherDomHeight: 0,
      drawerForm: {
        trackingAt: '',
        trackingAtGte: '',
        trackingAtLte: '',
        contactNameCont: '',
        policymakerCont: '',
        keyword: '',
        dateVal: [],
        page: 1,
        pagesize: 20,
      },

      chooseInputArr: [
        {
          prop: 'trackingAt',
        },
        {
          prop: 'bdName',
        },
        {
          prop: 'sponsorName',
        },
        {
          prop: 'contactName',
        },
        {
          prop: 'customerDemand',
        },
        {
          prop: 'policyFlow',
        },
        {
          prop: 'actionPlan',
        },
        {
          prop: 'contactPosition',
        },
        {
          prop: 'contactPhone',
        },
        {
          prop: 'projectCountPerYear',
        },
        {
          prop: 'projectBeginAt',
        },
        {
          prop: 'projectByStages',
        },
        {
          prop: 'projectBudget',
        },
        {
          prop: 'policymaker',
        },
      ],
      startPlaceholder: '开始日期',
      endPlaceholder: '结束日期',
      choosedContractItem: '',
      recordVisable: false,
      uploadFd: null,
      uploadForm: {
        fileList: [],
        ruleCheck: [],
      },
      uploadRules: {
        fileList: [{ type: 'array', required: true, message: '请至少选择一项文件上传', trigger: 'change' }],
      },
      recordRules: {
        bdId: [{ type: 'string', required: true, message: '请输入或者下拉选择', trigger: 'blur' }],
        contactPhone: [{ type: 'string', required: true, message: '请输入', trigger: 'blur' }],
        trackingAt: [{ type: 'string', required: true, message: '请选择', trigger: 'blur' }],
        contactName: [{ type: 'string', required: true, message: '请输入或者下拉选择', trigger: 'change' }],
        contactPosition: [{ type: 'string', required: true, message: '请输入', trigger: 'blur' }],
      },
      recordForm: {
        id: '',
        trackingAt: '',
        bdId: '',
        contactName: '',
        contactPosition: '',
        contactPhone: '',
        policymaker: '',
        projectCountPerYear: 0,
        projectByStages: 0,
        projectBudget: 0,
        projectBeginAt: '',
        sponsorId: '',
        customerDemand: '',
        policyFlow: '',
        actionPlan: '',
        customerId: '',
        sponsorName: '',
        bdName: '',
      },
      optionGroup: {
        sponsorOption: [],
        customerOption: [],
      },
      uploadVisable: false,
      resError: false,
      inputContent: '',
    };
  },
  created() {
    this.drawerAuthGroup.create = this.$store.state.app.userConfig.permissionsKeys?.ClientTrackingManagementCreate;
    this.drawerAuthGroup.update = this.$store.state.app.userConfig.permissionsKeys?.ClientTrackingManagementUpdate;
    this.drawerAuthGroup.del = this.$store.state.app.userConfig.permissionsKeys?.ClientTrackingManagementDelete;
  },
  methods: {
    pageChange(type, vals) {
      if (type === 'size') {
        this.drawerForm.pagesize = vals;
      } else {
        this.drawerForm.page = vals;
      }
      this.getTableList();
    },
    querySearch(queryString, cb) {
      const customerEqs = [...this.optionGroup.customerOption];
      const results = queryString ? customerEqs.filter(this.createFilter(queryString)) : customerEqs;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      return customerEqs => {
        return customerEqs.name.toLowerCase().indexOf(queryString.toLowerCase()) === 0;
      };
    },
    async handleSelect(item) {
      this.recordForm.contactPhone = '';
      this.recordForm.contactPosition = '';
      let [customerInfoData] = await resourceManagementApi.getContactList({
        page: 1,
        pagesize: 999999,
        customerIdEq: this.customer_row.id,
        nameCont: item.name,
      });
      this.recordForm.contactPhone = customerInfoData.customerContacts.length ? customerInfoData.customerContacts[0].invoiceEmail : '';
      this.recordForm.contactPosition = customerInfoData.customerContacts.length ? customerInfoData.customerContacts[0].position : '';
    },
    async handleChange(val) {
      // 避免重复请求和扩大模糊搜索范围
      if (!val || !this.optionGroup.customerOption.find(i => i.name === val)) {
        return;
      }
      let [customerInfoData] = await resourceManagementApi.getContactList({
        page: 1,
        pagesize: 999999,
        customerIdEq: this.customer_row.id,
        nameCont: val,
      });
      this.recordForm.contactPhone = customerInfoData.customerContacts.length ? customerInfoData.customerContacts[0].invoiceEmail : '';
      this.recordForm.contactPosition = customerInfoData.customerContacts.length ? customerInfoData.customerContacts[0].position : '';
    },
    async editItem(row) {
      this.recordForm = Object.assign({}, row);
      this.recordVisable = true;
    },
    async deleteItem(id) {
      await resourceManagementApi.delRecord(id);
      this.getTableList();
    },
    addRecord() {
      this.recordForm = {
        id: '',
        trackingAt: '',
        bdId: '',
        contactName: '',
        contactPosition: '',
        contactPhone: '',
        policymaker: '',
        projectCountPerYear: 0,
        projectByStages: 0,
        projectBudget: 0,
        projectBeginAt: '',
        sponsorId: '',
        customerDemand: '',
        policyFlow: '',
        actionPlan: '',
        customerId: '',
        sponsorName: '',
        bdName: '',
      };
      this.recordVisable = true;
    },
    handleRemove() {
      this.uploadFd = null;
      this.uploadForm.ruleCheck = [];
      this.uploadForm.fileList = [];
    },
    httpRequest(params) {
      this.$nextTick(() => {
        this.uploadForm.fileList = [];
        this.uploadForm.ruleCheck = [];
        this.uploadForm.ruleCheck.push(params.action);
        this.uploadForm.fileList.push(params.file);
        this.uploadFd = new FormData();
        this.uploadFd.append('file', params.file);
      });
    },
    uploadRecord() {
      this.$refs.uploadForm.validate(async valid => {
        if (valid) {
          this.uploadFd.append('customerId', this.customer_row.id);
          let res = await resourceManagementApi.uploadRecord(this.uploadFd);
          if (!res[1].errors?.length) {
            this.$message.success('导入成功');
            this.resError = false;
            this.leadingInCancel();
            this.getTableList();
            return;
          }
          if (res[1].errors.length) {
            this.resError = true;
            this.$message.error('导入失败!');
            // this.$emit('update:leadingInVisable', false);
            return;
          }
        }
      });
    },
    getTableList() {
      const submitForm = Object.assign({}, this.drawerForm);
      submitForm.customerIdEq = this.customer_row.id;
      submitForm.trackingAtLte = this.drawerForm.dateVal[1] ? dayFormat(this.drawerForm.dateVal[1], 'YYYY-MM-DD') : '';
      submitForm.trackingAtGte = this.drawerForm.dateVal[0] ? dayFormat(this.drawerForm.dateVal[0], 'YYYY-MM-DD') : '';
      submitForm.dateVal = null;
      resourceManagementApi
        .getRecordList(submitForm)
        .then(res => {
          this.tableLoading = true;
          // 唯一的抽屉主页面变更table数据的途径
          this.oriTableData = [...res[0].customerTrackings];
          this.count = res[0].count ? res[0].count : 0;
          this.tableLoading = false;
        })
        .catch(err => {
          this.drawerForm.page = 1;
          this.drawerForm.pagesize = 20;
          console.log(err);
          this.$message.error('请求出错！');
          this.tableLoading = false;
        });
    },
    handleSizeChange(val) {
      this.drawerForm.pagesize = val;
      this.getTableList();
    },
    handleCurrentChange(val) {
      this.drawerForm.page = val;
      this.getTableList();
    },
    resizeObserverSize() {
      const topBoxHeight = this.$refs.formContainer?.offsetHeight + this.$refs.drawerHeader?.offsetHeight;
      const topHeight = topBoxHeight ? topBoxHeight : 0;
      const tableHeaderHeight = this.$refs.tableHeader?.offsetHeight ? this.$refs.tableHeader.offsetHeight : 0;
      this.otherDomHeight = topHeight + tableHeaderHeight + 30;
      this.proDrawerHeight = window.innerHeight - this.otherDomHeight - 64;
    },
    leadingInCancel() {
      this.uploadForm.ruleCheck = [];
      this.uploadFd = null;
      this.uploadForm.fileList = [];
      this.uploadVisable = false;
    },

    recordClose(val) {
      this.$confirm(val, '', {
        confirmButtonText: this.$t('commonVariables.complete'),
        cancelButtonText: this.$t('commonVariables.cancel'),
      }).then(() => {
        this.recordVisable = false;
        this.$nextTick(() => {
          this.$refs.recordForm.clearValidate();
          this.$refs.recordForm.resetFields();
        });
      });
    },
    async recordSave() {
      this.$refs.recordForm.validate(async valid => {
        if (!valid) {
          return;
        }
        this.recordForm.customerId = this.customer_row.id;
        let [data] = this.recordForm.id
          ? await resourceManagementApi.updateRecord(this.recordForm, this.recordForm.id)
          : await resourceManagementApi.createRecord(this.recordForm);
        if (!data && data !== null) {
          await this.getTableList();
          this.$message.success('保存成功');
          this.recordVisable = false;
          this.$nextTick(() => {
            this.$refs.recordForm.resetFields();
            this.$refs.recordForm.clearValidate();
          });
        }
      });
    },
    afterCloseClear() {
      // 关闭清空
      this.$refs.drawerForm.resetFields();
      this.drawerForm.page = 1;
      this.drawerForm.pagesize = 20;
      this.count = 0;
      this.oriTableData = [];
      this.$emit('update:drawerVisable', false);
    },
    async getDrawerOptions() {
      let [sponsorData] = await resourceManagementApi.sponsorData({ page: 1, pagesize: 999999 });
      let [customerData] = await resourceManagementApi.getContactList({ page: 1, pagesize: 999999, customerIdEq: this.customer_row.id });
      this.optionGroup.customerOption = [...customerData.customerContacts];
      this.optionGroup.sponsorOption = [...sponsorData.sponsors];
      this.optionGroup.bdOption = [...this.allBds];
    },
    //添加服务项
    search() {
      this.drawerForm.page = 1;
      this.drawerForm.pagesize = 20;
      this.count = 0;
      this.getTableList();
    },
    resetForm() {
      this.$refs.drawerForm.resetFields();
      this.$refs.drawerForm.clearValidate();
    },
  },
};
</script>

<style lang="scss" scoped>
#projFormDrawer {
  ::v-deep .el-form-item__label {
    font-weight: normal;
    color: #515a6e;
  }
  .btn {
    height: 32px;
    padding: 0 20px;
  }
  .drawerHeader {
    display: flex;
    font-size: 16px;

    font-weight: 500;
    color: #17233d;
    padding: 0 32px;
    align-items: center;
    margin: 0;
    .title {
      font-family: MicrosoftYahei, Helvetica, Arial, sans-serif;
    }
    .box {
      display: inline-block;
      position: relative;
      // min-width: 92px;
      min-height: 24px;
      margin-left: 8px;

      background: #f5f7fa;
      border-radius: 4px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #2d8cf0;
      .circle {
        position: absolute;
        top: calc(50% - 3px);
        left: 12px;
        border-radius: 50%;
        width: 6px;
        height: 6px;
        background: #2d8cf0;
      }
      .currentRord {
        color: #515a6e;
        display: inline-block;
        margin: 0;
        padding-left: 24px;
        padding-right: 12px;
        line-height: 24px;
      }
    }
    .header-btn-group {
      margin-left: auto;
      .closeDrawder {
        cursor: pointer;
        font-size: 14px;
        color: #2d8cf0;
        margin-left: 16px;
      }
    }
  }
  .app-box {
    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0 2px;
      p {
        font-size: 16px;
        font-weight: 600;
        color: #19233b;
      }
      .header-btns {
        display: flex;
        align-items: center;
        height: 36px;
        font-size: 14px;
        .closeDrawder {
          margin-left: 16px;
        }
      }
    }

    .form-box {
      ::v-deep .el-input__inner {
        min-width: 207px;
        height: 32px;
      }

      .formLabel {
        ::v-deep .el-form-item__content {
          display: flex;
          align-items: center;
          margin-left: 0 !important;
        }
      }
      .buttonGroup {
        justify-content: flex-end;
        margin-bottom: 2px !important;
      }
      display: flex;
      flex-wrap: wrap !important;
      justify-content: space-around;
      align-items: center;

      width: 100%;
      font-size: 14px;
      background-color: #fff;
      border-radius: 2px;
      font-size: 16px;
      font-weight: 500;
      padding: 20px 24px;
      padding-left: 32px;
      color: #17233d;
      border-top: 1px solid #e9e9e9;
      .el-form-item {
        display: flex;
        flex: auto;
        flex-wrap: nowrap;
        margin-bottom: 0;
        .el-date-picker {
          height: 32px;
        }
        .el-input {
          width: 224px;
        }
      }
    }
    .bottom-box {
      background-color: #fff;
      padding: 0 24px;
      padding-left: 32px;
      border-radius: 2px;
      .editServiceTable {
        margin-top: 0;
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 0;
        ::-webkit-scrollbar {
          width: 5px;
          height: 10px;
          border-radius: 15px;
        }
      }
    }
  }
  .record {
    ::v-deep .el-drawer__header {
      border-bottom: 1px solid #e8eaec;
      margin-bottom: 0;
      padding-bottom: 18px;
      span {
        font-size: 19px;
        font-weight: 700;
        color: #19233b;
      }
    }
    .closeIcon {
      cursor: pointer;
      position: absolute;
      top: 20px;
      right: 25px;
      font-size: 18px;
    }
    .recordForm {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      margin: 0 24px;
      padding-top: 14px;
      .leftForm {
        padding-right: 70px;
      }
      ::v-deep .el-input__inner {
        height: 32px;
        width: 248px;
      }
    }

    .textareaZone {
      margin: 0 24px;
      margin-left: 28px;
    }
    .button-group {
      display: flex;
      justify-content: flex-end;
      margin-right: 20px;
    }
  }
}
.downUrl {
  color: #409eff;
  margin-left: 10px;
}
.foot-button-group {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  .el-button {
    height: 32px;
    line-height: 8px;
  }
}
</style>
