<template>
  <el-drawer append-to-body id="projFormDrawer" :with-header="false" :visible="drawerVisable" :size="drawerSize">
    <!--   :title="" -->
    <div class="drawerHeader" ref="drawerHeader">
      <p class="title">{{ $t('ResourceManagement.customer.details') }}</p>
      <div class="box">
        <div class="circle"></div>
        <p class="currentRord">{{ this.customerInfo }}</p>
      </div>
      <div class="header-btn-group">
        <p @click="handleClose" class="closeDrawder">{{ $t('ResourceManagement.customer.backToList') }}</p>
      </div>
    </div>
    <div class="app-box">
      <div ref="formContainer" class="formContainer">
        <p class="tit">{{ $t('ResourceManagement.customer.basicInformation') }}</p>
        <el-form ref="customerForm" class="form-box" :model="customerForm">
          <el-form-item prop="area" :label="$t('ResourceManagement.customer.area')">
            <el-input v-model="customerForm.area" :disabled="!detailAuthGroup.formUpdate"></el-input>
          </el-form-item>
          <el-form-item prop="address" :label="$t('ResourceManagement.customer.address')">
            <el-input v-model="customerForm.address" :disabled="!detailAuthGroup.formUpdate"></el-input>
          </el-form-item>
          <div class="bottom-btns">
            <!-- <el-button class="btn" size="mini" type="default" @click="recordClose">取消</el-button> -->
            <el-button class="btn" size="mini" type="primary" @click="infoSave" :disabled="!detailAuthGroup.formUpdate">{{
              $t('commonVariables.save')
            }}</el-button>
          </div>
        </el-form>
        <p class="tit projTit">{{ $t('ResourceManagement.customer.projectSituation') }}</p>
        <span>
          {{
            $t('ResourceManagement.customer.doingProjectCount') + ':' + customerForm.doingProjectCount + $t('ResourceManagement.customer.projects')
          }}
        </span>
        <span>
          {{ $t('ResourceManagement.customer.finished') + ':' + customerForm.finishProjectCount + $t('ResourceManagement.customer.projects') }}
        </span>
      </div>

      <div class="bottom-box">
        <div class="header" ref="tableHeader">
          <p class="tit">{{ $t('ResourceManagement.customer.contact') }}</p>
          <span class="tips">{{ $t('ResourceManagement.customer.fillInPersonTips') }}</span>
          <div class="header-btns">
            <el-button @click="addContact" class="btn" type="primary" size="mini" :disabled="!detailAuthGroup.formUpdate">
              {{ $t('ResourceManagement.detail.addContact') }}
            </el-button>
          </div>
        </div>
        <EditServiceItemTable
          ref="table"
          :applyComp="'detail'"
          :tableLoading="tableLoading"
          :tableHeight="proDrawerHeight"
          :isIndex="false"
          :editDiago="true"
          :authGroup="detailAuthGroup"
          :deleteTips="$t('ResourceManagement.customer.deleteContactTips')"
          @deleteData="deleteItem"
          @editServiceItem="editItem"
          :serviceTableData="oriTableData"
          :chooseInputArr="chooseInputArr"
          :count="count"
          @pageChange="pageChange"
          :page.sync="page"
          :pagesize.sync="pagesize"
        />
      </div>
    </div>
    <SimpleDialog
      class="record"
      :visible.sync="contactVisable"
      :width="$i18n.locale == 'en' ? '590px' : '526px'"
      :modal="false"
      :appendFront="false"
      height="250"
      :title="recordForm.id ? $t('ResourceManagement.customer.editContact') : $t('ResourceManagement.customer.addContact')"
      :showConfirm="false"
      :showCancel="false"
      @onCancel="recordClose"
    >
      <el-form
        ref="recordForm"
        class="recordForm"
        label-position="right"
        :label-width="$i18n.locale == 'en' ? '160px' : '100px'"
        :model="recordForm"
        :rules="recordRules"
      >
        <el-form-item prop="name" :label="$t('ResourceManagement.customer.contactPersonName')">
          <el-input v-model.number="recordForm.name" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item prop="types" :label="$t('ResourceManagement.customer.contactType')">
          <!-- <my-select :options="typeOption" v-model="recordForm.type" placeholder="请输入或者下拉选择"></my-select> -->
          <el-select v-model="recordForm.types" placeholder="请输入或者下拉选择" multiple collapse-tags>
            <el-option v-for="item in typeOption" :key="item.id" :label="item.name" :value="item.id" />
          </el-select>
        </el-form-item>
        <el-form-item prop="position" :label="$t('ResourceManagement.customer.contactPosition')">
          <el-input v-model="recordForm.position" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item prop="email" label="Email">
          <el-input v-model="recordForm.email" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item prop="invoiceEmail" :label="$t('ResourceManagement.detail.invoiceEmail')">
          <el-input v-model="recordForm.invoiceEmail" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item prop="businessPhone" :label="$t('ResourceManagement.detail.businessPhone')">
          <el-input v-model="recordForm.businessPhone" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item prop="ccEmail" :label="$t('ResourceManagement.detail.ccEmail')">
          <el-input v-model="recordForm.ccEmail" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item :label="$t('ResourceManagement.customer.associatedContracts')" class="fliterInput">
          <div class="inputZone">
            <el-input @input="filterContract" v-model="contractKeyword" :placeholder="$t('ResourceManagement.customer.enterContractNo')"> </el-input>
            <div class="addContract" @click="addContract">+</div>
          </div>
          <i class="el-icon-search searchIcon"></i>
          <div class="contractList">
            <ul>
              <li v-for="item in filterContractList" :key="item.id" class="contractItem">
                <span>{{ item.name }}</span>
                <i @click="delItem(item.id, 'choosedContractItem')" class="el-icon-circle-close del-icon"></i>
              </li>
            </ul>
          </div>
        </el-form-item>
      </el-form>
      <SimpleDialog
        class="contractDialog"
        :appendFront="true"
        :modal="true"
        :title="$t('ResourceManagement.customer.addContracts')"
        :visible.sync="dialogVisible"
        width="345px"
        :show-close="false"
        top="400px"
        :before-close="handleClose"
      >
        <div class="addContractContainer">
          <memberSelect
            :projectUpdate="true"
            :memberSelected="addContractList"
            :specifiedPlaceholder="$t('ResourceManagement.customer.enterContractNo')"
            :allUser="contractIdOptions"
            @changeMemberSelected="filterAddContract"
            :optionLabel="'name'"
          ></memberSelect>
          <div class="addContractIdList">
            <ul>
              <li class="contractItem" v-for="item in addFilterContractItems" :key="item.id">
                <span>{{ item.name }}</span>
                <i @click="delItem(item.id, 'addContractItem')" class="el-icon-circle-close add-icon"></i>
              </li>
            </ul>
          </div>
          <div class="footerContainer">
            <el-button @click="detailClose">{{ $t('commonVariables.cancel') }}</el-button>
            <el-button type="primary" @click="addContractSubmit">{{ $t('commonVariables.add') }}</el-button>
          </div>
        </div>
      </SimpleDialog>
      <div class="mainFooterContainer">
        <el-button @click="recordClose">{{ $t('commonVariables.cancel') }}</el-button>
        <el-button type="primary" @click="recordSave">{{ $t('commonVariables.complete') }}</el-button>
      </div>
    </SimpleDialog>
  </el-drawer>
</template>

<script>
import resourceManagementApi from '@/api/modules/resourceManagement.api';
import contractManagementApi from '@/api/modules/contractManagement.api';
import memberSelect from '@/components/memberSelect/index.vue';

import EditServiceItemTable from './EditServiceItemTable';
import debounce from 'lodash/debounce'; // 防抖函数

export default {
  components: {
    EditServiceItemTable,
    memberSelect,
  },
  name: 'ProjFormDrawer',
  props: {
    boOption: {
      type: Array,
      default() {
        return [];
      },
    },
    customer_row: {
      type: Object,
      default() {
        return null;
      },
    },
    drawerVisable: {
      type: Boolean,
      default: false,
    },
    tableType: {
      type: String,
      default: 'create',
    },
    closeTips: {
      type: String,
      default: '',
    },
  },
  watch: {
    drawerVisable: {
      immediate: false,
      deep: true,
      handler(newVal) {
        if (newVal && this.firstCreate) {
          this.$nextTick(() => {
            this.resizeObserverSize();
          });
          window.addEventListener('resize', this.resizeObserverSize);
          this.firstCreate = false;
        }
        if (newVal) {
          this.customerForm = Object.assign({}, this.customer_row);
          this.getTableList();
          this.getContractList();
          this.customerInfo = this.customer_row ? this.customer_row.code + '   ' + this.customer_row.name : '';
        }
      },
    },
  },

  activated() {
    this.$nextTick(() => {
      !this.firstCreate ? this.resizeObserverSize() : null;
    });
    window.addEventListener('resize', this.resizeObserverSize);
  },
  deactivated() {
    window.removeEventListener('resize', this.resizeObserverSize);
  },

  data() {
    return {
      dialogVisible: false,
      proDrawerHeight: 0,
      firstCreate: true,
      drawerSize: '90%',
      detailAuthGroup: {
        create: false,
        edit: false,
        del: false,
        read: false,
        formUpdate: false,
      },
      typeOption: [
        {
          name: this.$t('ResourceManagement.customer.businessContact'),
          id: 'business',
          translation: true,
        },
        {
          name: this.$t('ResourceManagement.customer.procurementContact'),
          id: 'purchase',
          translation: true,
        },
        {
          name: this.$t('ResourceManagement.customer.accountant'),
          id: 'accountant',
          translation: true,
        },

        {
          name: this.$t('ResourceManagement.customer.dm'),
          id: 'dm',
          translation: true,
        },
        {
          name: this.$t('ResourceManagement.customer.stat'),
          id: 'stat',
          translation: true,
        },
        {
          name: this.$t('ResourceManagement.customer.pk'),
          id: 'pk',
          translation: true,
        },
        {
          name: this.$t('ResourceManagement.customer.med'),
          id: 'med',
          translation: true,
        },
        {
          name: this.$t('ResourceManagement.customer.co'),
          id: 'co',
          translation: true,
        },
        {
          name: this.$t('ResourceManagement.customer.prog'),
          id: 'prog',
          translation: true,
        },
        {
          name: this.$t('ResourceManagement.customer.else'),
          id: 'other',
          translation: true,
        },
      ],
      tableLoading: false,
      tableList: [],
      oriTableData: [],
      customerInfo: '',
      count: 0,
      otherDomHeight: 0,
      customerForm: {},

      chooseInputArr: [
        {
          prop: 'name',
        },
        {
          prop: 'types',
        },
        {
          prop: 'position',
        },
        {
          prop: 'email',
        },
        {
          prop: 'invoiceEmail',
        },
        {
          prop: 'businessPhone',
        },
        {
          prop: 'ccEmail',
        },
      ],
      startPlaceholder: '开始月份',
      endPlaceholder: '结束月份',

      contactVisable: false,

      recordRules: {
        name: [{ type: 'string', required: true, message: '请输入', trigger: 'blur' }],
      },
      contractKeyword: '',
      addContractList: [],
      showMemberSelectedName: [],
      recordForm: {
        id: '',
        name: '',
        // type: '',
        types: [],
        position: '',
        email: '',
        invoiceEmail: '',
        businessPhone: '',
        ccEmail: '',
        contractIds: [],
        customerId: '',
      },
      filterContractList: [],
      backUplist: [],
      addFilterContractIds: [],
      addFilterContractItems: [],
      contractIdOptions: [],
      page: 1,
      pagesize: 20,
    };
  },
  created() {
    this.detailAuthGroup.create = this.$store.state.app.userConfig.permissionsKeys?.ClientContactManagementCreate;
    this.detailAuthGroup.update = this.$store.state.app.userConfig.permissionsKeys?.ClientContactManagementUpdate;
    this.detailAuthGroup.del = this.$store.state.app.userConfig.permissionsKeys?.ClientContactManagementDelete;
    this.detailAuthGroup.read = this.$store.state.app.userConfig.permissionsKeys?.ClientContactManagementRead;
    this.detailAuthGroup.formUpdate = this.$store.state.app.userConfig.permissionsKeys?.ClientManagementDetailUpdate;
  },
  methods: {
    pageChange(type, vals) {
      if (type === 'size') {
        this.pagesize = vals;
      } else {
        this.page = vals;
      }
      this.getTableList();
    },
    delItem(choosedId, type) {
      if (type === 'choosedContractItem') {
        const delIdx = this.filterContractList.findIndex(val => val.id == choosedId);
        this.filterContractList.splice(delIdx, 1);
        this.backUplist = JSON.parse(JSON.stringify(this.filterContractList));
        this.filterContract();
      } else {
        const delItem = this.addFilterContractItems.findIndex(val => val.id == choosedId);
        this.addFilterContractItems.splice(delItem, 1);
        const delId = this.addFilterContractIds.findIndex(val => val == choosedId);
        this.addFilterContractIds.splice(delId, 1);
        const selectId = this.addContractList.findIndex(val => val == choosedId);
        this.addContractList.splice(selectId, 1);
      }
    },
    addContract() {
      this.addFilterContractIds = [];
      this.addFilterContractItems = [];
      this.dialogVisible = true;
    },
    editItem(row) {
      this.filterContractList = this.contractIdOptions.filter(item => {
        return row.contractIds.includes(item.id);
      });
      this.backUplist = JSON.parse(JSON.stringify(this.filterContractList));
      this.recordForm = Object.assign({}, row);
      this.contactVisable = true;
    },
    filterContract: debounce(function () {
      if (!this.contractKeyword) {
        this.filterContractList = JSON.parse(JSON.stringify(this.backUplist));
        return;
      } else {
        console.log(this.filterContractList);

        let filterList = this.filterContractList.filter(item => item.name.toString().indexOf(this.contractKeyword) >= 0);
        this.filterContractList = [...filterList];
      }
    }, 300),
    filterAddContract(label, ids) {
      this.addContractList = [];
      this.showMemberSelectedName = [...label];
      this.addFilterContractIds = [...ids];
      const memberIds = [...ids];
      memberIds.forEach(item => {
        this.addContractList.push(item);
      });
      this.addFilterContractItems = this.contractIdOptions.filter(val => {
        return ids.includes(val.id);
      });
    },
    async deleteItem(id) {
      await resourceManagementApi.deleteContactPerson(id);
      this.getTableList();
    },
    async getContractList() {
      let [data] = await contractManagementApi.getContractList({ customerIdEq: this.customer_row?.id });
      this.contractIdOptions = [...data.contracts];
    },
    addContact() {
      this.recordForm = {
        id: '',
        name: '',
        // type: '',
        types: [],
        email: '',
        position: '',
        invoiceEmail: '',
        businessPhone: '',
        ccEmail: '',
        contractIds: [],
        customerId: '',
      };
      this.filterContractList = [];
      this.contactVisable = true;
    },
    getTableList() {
      if (!this.detailAuthGroup.read) {
        return;
      }
      resourceManagementApi.getContactList({ page: this.page, pagesize: this.pagesize, customerIdEq: this.customerForm.id }).then(res => {
        this.tableLoading = true;
        this.count = res[0].count ? res[0].count : 0;
        this.oriTableData = [...res[0].customerContacts];
        this.tableLoading = false;
      });
    },
    resizeObserverSize() {
      const topBoxHeight = this.$refs.formContainer?.offsetHeight + this.$refs.drawerHeader?.offsetHeight;
      const topHeight = topBoxHeight ? topBoxHeight : 0;
      const tableHeaderHeight = this.$refs.tableHeader?.offsetHeight ? this.$refs.tableHeader.offsetHeight : 0;
      this.otherDomHeight = topHeight + tableHeaderHeight + 30;
      this.proDrawerHeight = window.innerHeight - this.otherDomHeight - 42;
    },
    handleClose() {
      this.afterCloseClear();
    },
    recordClose() {
      this.$confirm(this.closeTips, '', {
        confirmButtonText: this.$t('commonVariables.complete'),
        cancelButtonText: this.$t('commonVariables.cancel'),
      }).then(() => {
        this.contactVisable = false;
        this.$nextTick(() => {
          this.$refs.recordForm.clearValidate();
          this.$refs.recordForm.resetFields();
        });
      });
    },
    detailClose() {
      this.addContractList = [];
      this.showMemberSelectedName = [];
      this.addFilterContractItems = [];
      this.dialogVisible = false;
    },
    addContractSubmit() {
      if (!this.addFilterContractItems.length) {
        this.$message.error('请至少选择一项');
        return;
      }
      this.addFilterContractItems.map(val => {
        this.filterContractList.find(item => item.id == val.id) ? null : this.filterContractList.push(val);
      });
      this.backUplist = JSON.parse(JSON.stringify(this.filterContractList));
      this.detailClose();
    },
    async recordSave() {
      this.$refs.recordForm.validate(async valid => {
        if (!valid) {
          return;
        }
        this.recordForm.contractIds = this.filterContractList.map(item => item.id);
        this.recordForm.customerId = this.customer_row.id;
        let [data] = this.recordForm.id
          ? await resourceManagementApi.updateContactPerson(this.recordForm, this.recordForm.id)
          : await resourceManagementApi.createContactPerson(this.recordForm);
        if (!data && data !== null) {
          await this.getTableList();
          this.$message.success('保存成功');
          this.contactVisable = false;
          this.$nextTick(() => {
            this.$refs.recordForm.resetFields();
            this.$refs.recordForm.clearValidate();
          });
        }
      });
    },
    async infoSave() {
      const infoForm = {
        area: this.customerForm.area,
        address: this.customerForm.address,
      };
      let [data] = await resourceManagementApi.updateCustomersAddress(infoForm, this.customerForm.id);
      if (!data && data !== null) {
        this.$message.success('保存成功');
        this.$emit('searchForm');
      }
    },
    afterCloseClear() {
      // 关闭清空
      this.page = 1;
      this.pagesize = 20;
      this.count = 0;
      this.oriTableData = [];
      this.$emit('update:drawerVisable', false);
    },
    //添加服务项
    search() {
      this.getTableList();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-input__suffix {
  display: flex;
  align-items: center;
}
#projFormDrawer {
  overflow: auto !important;
  .btn {
    height: 32px;
    padding: 0 20px;
  }
  .drawerHeader {
    display: flex;
    justify-content: flex-start;
    font-size: 18px;
    font-weight: 600;
    color: #17233d;
    padding: 0 24px;
    align-items: center;
    margin: 0;
    border-bottom: 1px solid #e9e9e9;
    .title {
      font-family: MicrosoftYahei, Helvetica, Arial, sans-serif;
    }
    .box {
      display: inline-block;
      position: relative;
      // min-width: 92px;
      min-height: 24px;
      margin-left: 8px;

      background: #f5f7fa;
      border-radius: 4px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #2d8cf0;
      .circle {
        position: absolute;
        top: calc(50% - 3px);
        left: 12px;
        border-radius: 50%;
        width: 6px;
        height: 6px;
        background: #2d8cf0;
      }
      .currentRord {
        color: #515a6e;
        display: inline-block;
        margin: 0;
        padding-left: 24px;
        padding-right: 12px;
        line-height: 24px;
      }
    }
    .header-btn-group {
      margin-left: auto;
      .closeDrawder {
        cursor: pointer;
        font-size: 14px;
        font-weight: normal;
        color: #2d8cf0;
        margin-left: 16px;
      }
    }
  }
  .app-box {
    .tit {
      color: #17233d;
      padding: 16px 0;
      margin: 0;
      font-size: 16px;
      font-weight: 600;
    }
    .header {
      width: 100%;
      display: flex;
      flex: auto;
      align-items: center;
      justify-content: flex-start;
      margin: 0 2px;
      p {
        font-size: 16px;
        font-weight: 600;
        color: #19233b;
      }
      .header-btns {
        margin-left: auto;
        display: flex;
        align-items: center;
        height: 36px;
        font-size: 14px;
        .closeDrawder {
          margin-left: 16px;
        }
      }
    }

    .formContainer {
      padding: 0 24px;
      .projTit {
        margin: 0;
        padding: 18px 0;
      }

      .form-box {
        padding: 0;
        padding-bottom: 0;
        display: flex;
        justify-content: flex-start;
        flex-wrap: nowrap;
        flex: 1;
        width: 100%;
        font-size: 14px;
        background-color: #fff;
        border-radius: 2px;
        font-size: 16px;
        font-weight: 500;

        color: #17233d;

        ::v-deep .el-input__inner {
          min-width: 207px;
          height: 32px;
        }

        .formLabel {
          ::v-deep .el-form-item__content {
            display: flex;
            align-items: center;
            margin-left: 0 !important;
          }
        }
        .buttonGroup {
          justify-content: flex-end;
        }

        .el-form-item {
          display: flex;
          flex-wrap: nowrap;
          margin-bottom: 0;
          padding-right: 30px;
          .el-date-picker {
            height: 32px;
          }
          .el-input {
            width: 224px;
          }
        }
        .bottom-btns {
          width: 100%;
          display: flex;
          flex: 1;
          align-items: center;
          justify-content: flex-start;
          margin: 0 2px;
        }
      }
    }
    .bottom-box {
      background-color: #fff;
      padding: 0 24px;
      border-radius: 2px;
      .tit {
        padding: 24px 0;
        color: #17233d;
      }
      .tips {
        font-size: 13px;
        color: #da4f2a;
      }
      .editServiceTable {
        margin-top: 0;
        padding: 0;
        ::-webkit-scrollbar {
          width: 5px;
          height: 10px;
          border-radius: 15px;
        }
      }
    }
  }

  .button-group {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    padding-right: 24px;
  }
}

.record {
  ::v-deep .el-dialog {
    box-shadow: 0px 12px 24px 0px rgba(0, 0, 0, 0.1) !important;
    border-radius: 4px !important;
    border: 1px solid #f0f0f0 !important;
  }
  ::v-deep .el-input__inner {
    height: 36px;
    width: 328px;
  }
  .recordForm {
    margin: 0 32px;
    padding-top: 20px;
    .searchIcon {
      position: absolute;
      right: 51px;
      top: 14px;
    }
    .fliterInput {
      .inputZone {
        display: flex;
        align-items: center;
        .el-input {
          width: auto !important;
        }
        .addContract {
          vertical-align: middle;
          cursor: pointer;
          color: #2d8cf0;
          display: inline-block;
          font-size: 18px;
          width: 34px;
          height: 34px;
          border: 1px dashed #c5c8ce;
          border-radius: 3px;
          line-height: 32px;
          text-align: center;
          margin-left: auto;
          border-color: #2d8cf0;
        }
      }
    }

    .contractList {
      width: 328px;
      min-height: 50px;
      border: 1px solid #ebeef5;
      border-radius: 4px;
      box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.24);
      .contractItem {
        height: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 8px 10px !important;
        .del-icon {
          cursor: pointer;
          color: #2d8cf0;
        }
      }
    }
  }
  ::v-deep .el-dialog__footer {
    padding-right: 0 !important;
    padding-bottom: 20px !important;
    margin-right: 30px;
  }
}
.contractDialog {
  margin-left: 950px;
  .addContractContainer {
    margin: 20px 32px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    flex-wrap: wrap;
    ::v-deep .el-select {
      width: 280px !important;
    }
    ::v-deep .tipShadow {
      margin-left: 55px !important;
    }

    .addContractIdList {
      width: 280px;
      border: 1px dashed #d9d9d9;
      border-radius: 4px;
      box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.1);
      min-height: 50px;
      margin: 20px 0;
      height: 300px;
      overflow-y: auto;
      margin-top: 5px;
      margin-bottom: 0;

      .contractItem {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 8px 10px !important;
        .add-icon {
          cursor: pointer;
          color: #2d8cf0;
        }
      }
    }
    .footerContainer {
      margin-top: 24px !important;
      text-align: right;
      .el-button {
        height: 32px !important;
        padding: 0 20px;
      }
    }
  }
}
.mainFooterContainer {
  text-align: right;
  padding-bottom: 24px;
  padding-right: 32px;
  .el-button {
    height: 32px !important;
    padding: 0 20px;
  }
}
</style>
